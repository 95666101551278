import React from "react";
import { repeat } from "../utils/utils";

const PageHeader = ({ children }) => (
  children && repeat(children).map((content, index) => (
    <h1 key={`item-${index}`}>
      {content}
    </h1>
  ))
);

export default PageHeader;