import React from "react";
import { Helmet } from "react-helmet";
import CookieBanner from "../components/CookieBanner";
import Menu from "../components/Menu";
import favicon from "../images/favicon.ico";

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Hello there! There is no spoon."/>
        <title>pitkanen.org</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Menu />
      <div id="container">
        {children}
      </div>
      <CookieBanner />
      <div id="signature">pitkanen.org</div>
    </>
  );
};

export default Layout;