import React, { useEffect, useState } from "react";

const CookieBanner = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(true);
  
  useEffect(() => {
    const consentGiven = localStorage.getItem("cookiesAccepted");
    if (consentGiven !== 'true') {
      setCookiesAccepted(false);
    }
  }, []);

  const handleClick = () => {
    setCookiesAccepted(true);
    localStorage.setItem("cookiesAccepted", true);
  };
  
  return !cookiesAccepted ? (
    <div id="cookie-container">
      <div id="cookiebanner">
        This site uses cookies.
      </div>
      <button onClick={handleClick}>Accept</button>
    </div>
  ) : null;
};

export default CookieBanner;