import React from "react";
import { Link } from "gatsby";

const Menu = () => {
  return (
    <table id="menu">
      <thead>
        <tr>
          <th>
            <Link to="/">Home</Link>
          </th>
          <th>
            <Link to="/about">About</Link>
          </th>
        </tr>
      </thead>
    </table>
  );
};

export default Menu;